import React from 'react';
import LeftBanner from "./LeftBanner";
import RightBanner from "./RightBanner";


const Banner = () => {
  /*const [textWrite] =useTypewriter({
    words:["Full Stack Developer", "Computer science Teacher", "Data Manager"],
      loop:true,
      typeSpeed:70,
      deleteSpeed:50,
      delaySpeed:3000,
  });*/
      
   
  return (
    <section id="home" className="w-full  gap-10 xl:gap-0 py-20 pb-20 flex flex-col lgl:flex-row border-b-[1px]
    border-b-black">
    <LeftBanner/>
   
    <RightBanner/>

    </section>
  )
}

export default Banner