import React, { useState } from 'react'
import Title from "../layouts/Title"
import Education from "./Education"
import Skills from "./Skills"
import Achievement from "./Achievement"
import Experience from "./Experience"
import { useTranslation } from "react-i18next";
const Resume = () => {
  const [t,i18n] = useTranslation("global");
    const [educationData,setEducationData]=useState(true);
    const [skillData,setSkillData]=useState(false);
    const [achievementData,setAchievementData]=useState(false);
    const [experienceData,setExperienceData]=useState(false);
  return (
    <section
    id="resume"
    className="w-full py-20 border-b-[5px] border-b-black">
    

    <div className="flex justify-center items-center text-center">
        <Title
          title={t("resume.NUMBER_YEARS_OF_EXPERIENCE")}
          description={t("resume.MY_RESUME")}
        />
      </div>

      <div >
        <ul className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2">
          <li onClick={()=>setEducationData(true)
               & setSkillData(false)
               & setAchievementData(false)
               & setExperienceData(false)} 
               className={`${educationData ? "border-designColor rounded-lg" : "border-transparent"} resumeLi`}>{t("resume.Education_and_Experience")}</li>
          <li onClick={()=>setEducationData(false)
               & setSkillData(true)
               & setAchievementData(false)
               & setExperienceData(false)} 
               className={`${skillData ? "border-designColor rounded-lg" : "border-transparent"} resumeLi`}>{t("resume.Professional_Skills")}</li>
          
        </ul>
      </div>
    {educationData && <Education/>}
    {skillData     && <Skills/>}
    {achievementData && <Achievement/>}
    {experienceData && <Experience/>}
     
    </section>
  )
}

export default Resume