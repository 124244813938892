import React, { useState } from 'react'
import {RiStarFill} from "react-icons/ri"
import {HiArrowRight,HiArrowLeft} from "react-icons/hi"
import Slider from "react-slick";
import Title from "../layouts/Title"
import {quote, testimonialOne, testimonialTwo } from "../../assets"

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] hover:bg-black duration-300 rounded-md text-2xl text-gray-400 flex justify-center items-center absolute top-0 right-0 shadow-shadowOne cursor-pointer z-10"
      onClick={onClick}
    >
    <HiArrowRight/></div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="w-14 h-12 bg-[#0c1821] hover:bg-black duration-300 
      rounded-md text-2xl text-gray-400 flex justify-center items-center absolute top-0 right-20 shadow-shadowOne cursor-pointer z-10"
      onClick={onClick}
    >
    <HiArrowLeft/></div>
  );
}

const Testimonial = () => {
  const [dotActive,setDotActive] = useState(0);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow:<SamplePrevArrow />,
    beforeChange: (prev,next) => {
   setDotActive(next);
    },
    appendDots: dots => (
        <div
          style={{
            borderRadius: "10px",
            padding: "10px"
          }}
        >
          <ul style={{ 
            display: "flex",
            gap: "15px",
            justifyContent: "center",
            marginTop: "20px",

           }}> {dots} </ul>
        </div>
      ),
      customPaging: i => (
        <div
          style={
            i === dotActive
            ? {
              width: "12px",
              height: "12px",
              color: "blue",
              background: "gray",
              borderRadius: "50%",
              cursor: "pointer",
            }
            :
            {
              width: "12px",
            height: "12px",
            color: "blue",
            background: "#ff014f",
            borderRadius: "50%",
            cursor: "pointer",
            }
            
          }
        >
          {}
        </div>
      )
  };

  return (
    <section
    id="testimonial"
    className="w-full-1/2 py-20 border-b-[5px] border-b-black">
    

    <div className="flex justify-center items-center text-center">
        <Title
          title="WHAT CLIENTS SAY"
          description="Testimonial"
        />
    </div>

    <div className="max-w-6xl mx-auto">
        
        <Slider {...settings}>
          <div className="w-full">
             <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
                 <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#050506] to-[#010810] p-10 rounded-lg shadow-shadowOne 
                   justify-center flex flex-col gap-10"> 
                   <img className="h-72 rounded-lg object-cover" src={testimonialOne} alt="testimonialOne" 
                   />
                     <div><p className="text-sx uppercase text-designColor tracking-wide mb-2">LYCEE BILINGUE D'EDEA</p></div>
                 </div>

                   <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between bg-gradient-to-r from-[#0a0909] to-slate-400"> 
                   <img className="w-full lgl:w-[20%]" src={quote} alt="quote"/>
                     <div className="w-full h-[70%] py-10 bg-gradient-to-tr from-[#1e2024] to-[#23272b] 
                     rounded-lg shadow-shadowOne p-8 flex flex-col justify-center gap-8">

                     <div className="flex justify-between items-center py-6 border-b-2 border-b-gray-300">
                     <div>
                     <div>
                     <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                       Gestion des Bulletins de notes .
                     </h3>
                     <p className="text-base text-gray-400 mt-3">
                       Septembre 2018 - present
                     </p>
                   </div>
                     </div>
                      <div className="text-base text-yellow-500 flex gap-1"> 
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       </div>
                     </div>
                     <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia, fuga ipsam pariatur accusamus quaerat sit repudiandae?</p>
                     </div>
                   </div>
                 
                </div>
          </div>
          
          <div className="w-full">
             <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
                 <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#050506] to-[#010810] p-10 rounded-lg shadow-shadowOne 
                   justify-center flex flex-col md:flex md:justify-start lgl:justify-center gap-10"> 
                   <img className="h-72 rounded-lg object-cover" src={testimonialOne} alt="testimonialOne" 
                   />
                     <div>
                     <p className="text-sx uppercase text-designColor tracking-wide mb-2">LYCEE BILINGUE D'EDEA</p>
                     </div>
                 </div>

                   <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between bg-gradient-to-r from-[#0a0909] to-slate-400"> 
                   <img className="w-full md:w-[20%]" src={quote} alt="quote"/>
                     <div className="w-full h-[70%] py-10 bg-gradient-to-tr from-[#1e2024] to-[#23272b] 
                     rounded-lg shadow-shadowOne p-8 flex flex-col justify-center gap-8">

                     <div className="flex justify-between items-center py-6 border-b-2 border-b-gray-300">
                     <div>
                     <div>
                     <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                       Gestion des Bulletins de notes .
                     </h3>
                     <p className="text-base text-gray-400 mt-3">
                       Septembre 2018 - present
                     </p>
                   </div>
                     </div>
                      <div className="text-base text-yellow-500 flex gap-1"> 
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       </div>
                     </div>
                     <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia, fuga ipsam pariatur accusamus quaerat sit repudiandae?</p>
                     </div>
                   </div>
                 
                </div>
          </div>

          <div className="w-full">
             <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
                 <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#050506] to-[#010810] p-10 rounded-lg shadow-shadowOne 
                   justify-center flex flex-col gap-10"> 
                   <img className="h-72 rounded-lg object-cover" src={testimonialTwo} alt="testimonialTwo" 
                   />
                     <div><p className="text-sx uppercase text-designColor tracking-wide mb-2">APSOLDD (Association pour la Promotion de la solidarite et du Developpement Durable)</p></div>
                 </div>

                   <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between bg-gradient-to-r from-[#0a0909] to-slate-400"> 
                   <img className="w-full lgl:w-[20%]" src={quote} alt="quote"/>
                     <div className="w-full h-[70%] py-10 bg-gradient-to-tr from-[#1e2024] to-[#23272b] 
                     rounded-lg shadow-shadowOne p-8 flex flex-col justify-center gap-8">

                     <div className="flex justify-between items-center py-6 border-b-2 border-b-gray-300">
                     <div>
                     <div>
                     <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                       Integrateur des solutions informatiques .
                     </h3>
                     <p className="text-base text-gray-400 mt-3">
                       Septembre 2019 - present
                     </p>
                   </div>
                     </div>
                      <div className="text-base text-yellow-500 flex gap-1"> 
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       </div>
                     </div>
                     <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia, fuga ipsam pariatur accusamus quaerat sit repudiandae?</p>
                     </div>
                   </div>
                 
                </div>
          </div>

          <div className="w-full">
             <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
                 <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#050506] to-[#010810] p-10 rounded-lg shadow-shadowOne 
                   justify-center flex flex-col gap-10"> 
                   <img className="h-72 rounded-lg object-cover" src={testimonialOne} alt="testimonialOne" 
                   />
                     <div><p className="text-sx uppercase text-designColor tracking-wide mb-2">LYCEE BILINGUE D'EDEA</p></div>
                 </div>

                   <div className="w-full lgl:w-[60%] h-full flex flex-col justify-between bg-gradient-to-r from-[#0a0909] to-slate-400"> 
                   <img className="w-full lgl:w-[20%]" src={quote} alt="quote"/>
                     <div className="w-full h-[70%] py-10 bg-gradient-to-tr from-[#1e2024] to-[#23272b] 
                     rounded-lg shadow-shadowOne p-8 flex flex-col justify-center gap-8">

                     <div className="flex justify-between items-center py-6 border-b-2 border-b-gray-300">
                     <div>
                     <div>
                     <h3 className="text-xl lgl:text-2xl font-medium tracking-wide">
                       Gestion des Bulletins de notes .
                     </h3>
                     <p className="text-base text-gray-400 mt-3">
                       Septembre 2018 - present
                     </p>
                   </div>
                     </div>
                      <div className="text-base text-yellow-500 flex gap-1"> 
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       <RiStarFill/>
                       </div>
                     </div>
                     <p className="text-base font-titleFont text-gray-400 font-medium tracking-wide leading-6">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Officia, fuga ipsam pariatur accusamus quaerat sit repudiandae?</p>
                     </div>
                   </div>
                 
                </div>
          </div>
          
        </Slider>
      </div>
      </section>
  )
}

export default Testimonial