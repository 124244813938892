import React from 'react'
import ResumeCard from "./ResumeCard"
import {motion} from "framer-motion"
import { useTranslation } from "react-i18next";
const Education = () => {
  const [t,i18n] = useTranslation("global");
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20">
  { /*className="w-full flex gap-20">*/}
       {/* Part one */}
    <div>
    <div className="py-6 lgl:py-12 font-titleFont gap-20">
         <p className="text-sm text-designColor tracking-[px]">2010-2020</p>
         <h2 className="text-3xl md:text-4xl font-bold">{t("education.Etudes_et_formation")}</h2>
    
    </div>
    
      
    <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[5px] border-l-black border-opacity-30 flex flex-col gap-10">
      <ResumeCard 
      
      title={t("education.TITRE_DIPLOME_1")}
      subTitle={t("education.ECOLE_DIPLOME_1")}
      mention={t("education.MENTION_DIPLOME_1")}
      description="Cette formation fournie par l'École Doctorale des Sciences Fondamentales et Appliquées(EDOSFA) de l'Université de Douala 
      forme des spécialistes en sciences de l'ingénieur capable de concevoir, développer et déployer les systèmes informatiques de grande taille 
      et les prépare à la Recherche"
      />

      <ResumeCard 
      
      title="Diplôme de Professeur de l'Enseignement Technique de deuxième grade (DIPET II) en Informatique fondamentale"
      subTitle="ENSET-Université de Bamenda, 2015-2017"
      mention="BIEN"
      description="Cette formation fournie par l'École Normale des Enseignements Technique (ENSET) de l'Université de Bamenda
       forme des enseignants des lycées d’Enseignement Technique"
      />

      <ResumeCard 
      
      title="Licence (équivalent Baccalauréat) en mathématiques-informatique option 
      informatique fondamentale"
      subTitle="Université de Dschang"
      mention="ASSEZ BIEN"
      description="Cette formation fournie par l'Université de Dschang
      permet aux etudiants  de posséder les bases et les outils nécessaires pour travailler dans le secteur de l'Informatique"
      />
    </div>
    </div>

     {/* End Part one */}

    
     {/* Part Two */}
    <div>
    <div className="mt-14 lgl:mt-0 py-6 lgl:py-12 font-titleFont gap-20">
         <p className="text-sm text-designColor tracking-[px]">2018-2023</p>
         <h2 className="text-3xl md:text-4xl font-bold">{t("education.Job_Experience")}</h2>
    
    </div>
    
      
    <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[5px] border-l-black border-opacity-30 flex flex-col gap-10">
      <ResumeCard 
      
      title="Enseignant d'informatique / Gestionnaire de la cellule informatique"
      subTitle="Lycée Bilingue d'Edea"
      mention="Cameroun"
      description="Coordonner la cellule informatique 
      chargée de la gestion des notes des élèves.

      Créer et configurer un réseau local, développer 
      et configurer le système de gestion des notes pour 
      qu'il puisse fonctionner dans le réseau local.

      Imprimer les Bulletins des élèves.

      Préparer les leçons à enseigner aux élèves 
      conformément au programme approuvé par le ministère.
      
      Enseigner et évaluer les élèves en informatique.

      "
      />

      <ResumeCard 
      
      title="Intégrateur des solutions numériques / Gestionnaire de données (Travaille sous-commission)"
      subTitle="APSOLDD"
      mention="CAMEROUN"
      description="Développer et maintenir le site web de l’entreprise
       Installer les outils matériels ou logiciels de l’entreprise
       Adapter ou améliorer les programmes informatiques aux besoins de l’entreprise.
       Réaliser des audits dans le but d’apporter la meilleure solution
       Saisir des données dans des bases de données informatisées ou des tableurs
       Interprétation des résultats"
      />

      {/*<ResumeCard 
      
      title="Master 2(équivalent Maîtrise) Recherche en génie informatique"
      subTitle="EDSFA-Université de Douala"
      mention="ASSEZ BIEN"
      description="Cette formation fournie par l'Ecole Doctorale des Sciences Fondamentales et Appliquees(EDOSFA) de l'Universite de Douala
      forme des specialistes en sciences de l'ingenieur capable de concevoir, developper et deployer les systemes informatiques
      de grande taille et les prepare a la Recherche"
  />*/}
    </div>
    </div>
     {/*End Part Two */}
     
    </motion.div>
  )
}

export default Education