import React from 'react'
import {useTypewriter,Cursor} from 'react-simple-typewriter';
import {FaFacebookF,FaLinkedinIn,FaReact,FaWhatsapp} from 'react-icons/fa';
import {SiJava,SiPhp,SiMysql,SiPostgresql,SiMicrosoftexcel} from 'react-icons/si';
import { useTranslation } from "react-i18next";
import { BrowserRouter as  Router, Routes, Link} from "react-router-dom";
const LeftBanner = () => {
  const [t,i18n] = useTranslation("global");
    const [textWrite] =useTypewriter({
        words:[t("banner.Full_Stack_Developer"), t("banner.Computer_science_Teacher"), t("banner.Data_Manager")],
          loop:true,
          typeSpeed:70,
          deleteSpeed:50,
          delaySpeed:3000,
      });

    
  return (
    <div className="w-full lgl:w-2/3 flex flex-col gap-10">
    <div className="flex flex-col gap-5">
      <h4 className=" text-lg font-normal">{t("banner.welcome")}</h4>
      <h1 className="text-6xl font-bold text-white">
      {t("banner.presentation")} <span className="text-designColor capitalize">{t("banner.MY_NAME")},</span>
      </h1>
      <h2 className="text-4xl font-bold text-white">
         {textWrite}
        <Cursor 
        cursorBlinking="false"
        cursorStyle="|"
        cursorColor="#ff013f">
        </Cursor>
       
      </h2>
      <p className="text-base font-bodyFont leading-6 tracking-wide">
      {t("banner.Description")}
      </p>
    </div>
 
    <div className="bg-black-500 flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
    <div>{/*Je mets ce div pour que justify-between met l'espace entre ces deux elements*/}
     <h2 className="text-base uppercase font-titleFont mb-4">{t("banner.Find_me_on")}
     </h2>
        {/* Aligner les icones sur la meme ligne en utilisant felx, avec espace(gap) de 4*/}
        <div className="flex gap-4">
            <span className="bannerIcon">
         <a href="https://www.facebook.com/kouopehuie.sylvain/" target="_blank" rel="noreferrer"> <FaFacebookF/></a> 
          {/*<Link to="https://www.facebook.com" target="_blank" rel="noreferrer">ici </Link>*/}  
            </span>
 
            <span className="bannerIcon">
            <a href="https://www.linkedin.com/in/kouo-pehuie-sylvain-74164a161/" target="_blank" rel="noreferrer"> <FaLinkedinIn/></a> 
            
            </span>
            <span className="bannerIcon">
            <a href="https://wa.me/237658096189/" target="_blank" rel="noreferrer"><FaWhatsapp/></a>
            
            </span>
        </div>
        </div>
        <div>
        <h2 className="text-base uppercase font-titleFont mb-4">Best skill on
     </h2>
        {/* Aligner les icones sur la meme ligne en utilisant felx, avec espace(gap) de 4*/}
        <div className="flex gap-4">
            <span className="bannerIcon">
            <FaReact/>
            </span>
 
            <span className="bannerIcon">
            <SiJava/>
            </span>
            <span className="bannerIcon">
            <SiPhp/>
            </span>
 
            <span className="bannerIcon">
            <SiMysql/>
            </span>
 
            <span className="bannerIcon">
            <SiPostgresql/>
            </span>
            <span className="bannerIcon">
            <SiMicrosoftexcel/>
            </span>
           
        </div>
        </div>
    </div>
   
    </div>
  )
}

export default LeftBanner