import React from 'react'
import {motion} from 'framer-motion'

const Skills = () => {
  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 0.5 } }}
    className="py-12 font-titleFont flex flex-col lgl:flex-row gap-10 lgl:gap-20"
  >
    <div className="w-full lgl:w-1/2">
    <div className="py-4 font-titleFont flex flex-col gap-4">
         <p className="text-sm text-designColor tracking-[px] uppercase">Features</p>
         <h2 className="text-3xl lgl:text-4xl font-bold">DATA MANAGEMENT Skills</h2>
    
    </div>
       <div className="mt-5 w-full flex flex-col gap-6">
         <div className="overflow-x-hidden">
           <p className="text-sm uppercase font-medium">PostgreSql</p>
           <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
            <motion.span
            initial={{ x:"-100%",opacity: 0 }}
            animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
            className="w-full h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                        rounded-md relative">
              <span className="absolute -top-5 right-0">100%</span> 
            </motion.span>
           </span>
         </div>

         <div className="overflow-x-hidden">
           <p className="text-sm uppercase font-medium">MYSQL</p>
           <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
            <motion.span
            initial={{ x:"-100%",opacity: 0 }}
            animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
            className="w-full h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                        rounded-md relative">
              <span className="absolute -top-5 right-0">100%</span> 
            </motion.span>
           </span>
         </div>


         <div className="overflow-x-hidden">
           <p className="text-sm uppercase font-medium">ORACLE</p>
           <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
            <motion.span
            initial={{ x:"-100%",opacity: 0 }}
            animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
            className="w-[50%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                        rounded-md relative">
              <span className="absolute -top-5 right-0">50%</span> 
            </motion.span>
           </span>
         </div>

         <div className="overflow-x-hidden">
         <p className="text-sm uppercase font-medium">MS EXCEL</p>
         <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
          <motion.span
          initial={{ x:"-100%",opacity: 0 }}
          animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
          className="w-full h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                      rounded-md relative">
            <span className="absolute -top-5 right-0">100%</span> 
          </motion.span>
         </span>
       </div>
       </div>
    </div>


    <div className="w-full lgl:w-1/2">
    <div className="py-4 font-titleFont flex flex-col gap-4">
         <p className="text-sm text-designColor tracking-[px] uppercase">Features</p>
         <h2 className="text-3xl lgl:text-4xl font-bold">Development Skills</h2>
    
    </div>
       <div className="mt-5 w-full">
      
       <div className="mt-5 w-full flex flex-col gap-6">
       <div className="overflow-x-hidden">
         <p className="text-sm uppercase font-medium">REACT</p>
         <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
          <motion.span
          initial={{ x:"-100%",opacity: 0 }}
          animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
          className="w-[70%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                      rounded-md relative">
            <span className="absolute -top-5 right-0">70%</span> 
          </motion.span>
         </span>
       </div>

       <div className="overflow-x-hidden">
         <p className="text-sm uppercase font-medium">JAVASCRIPT</p>
         <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
          <motion.span
          initial={{ x:"-100%",opacity: 0 }}
          animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
          className="w-[80%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                      rounded-md relative">
            <span className="absolute -top-5 right-0">80%</span> 
          </motion.span>
         </span>
       </div>


       <div className="overflow-x-hidden">
         <p className="text-sm uppercase font-medium">CSS3</p>
         <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
          <motion.span
          initial={{ x:"-100%",opacity: 0 }}
          animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
          className="w-[80%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                      rounded-md relative">
            <span className="absolute -top-5 right-0">80%</span> 
          </motion.span>
         </span>
       </div>

       <div className="overflow-x-hidden">
       <p className="text-sm uppercase font-medium">HTML5</p>
       <span className="w-[90%] h-2 bgOpacity inline-flex rounded-md mt-4">
        <motion.span
        initial={{ x:"-100%",opacity: 0 }}
        animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
        className="w-full h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                    rounded-md relative">
          <span className="absolute -top-5 right-0">90%</span> 
        </motion.span>
       </span>
     </div>

     <div className="overflow-x-hidden">
       <p className="text-sm uppercase font-medium">java</p>
       <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
        <motion.span
        initial={{ x:"-100%",opacity: 0 }}
        animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
        className="w-[90%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                    rounded-md relative">
          <span className="absolute -top-5 right-0">90%</span> 
        </motion.span>
       </span>
     </div>
     <div className="overflow-x-hidden">
       <p className="text-sm uppercase font-medium">PHP</p>
       <span className="w-full h-2 bgOpacity inline-flex rounded-md mt-4">
        <motion.span
        initial={{ x:"-100%",opacity: 0 }}
        animate={{ x:0,opacity: 1, transition: { duration: 0.5, delay:0.5 } }}
        className="w-[80%] h-full bg-gradient-to-r from-blue-600 via-pink-500 to-red-500 
                    rounded-md relative">
          <span className="absolute -top-5 right-0">80%</span> 
        </motion.span>
       </span>
     </div>
     </div>
       </div>
    </div>

    
    </motion.div>
  )
}

export default Skills