import React from 'react'
import { contactImg } from "../../assets/index"
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaWhatsapp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const ContactLeft = () => {
  const [t,i18n] = useTranslation("global");
  return (
    <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#050506] to-[#010810] p-10 rounded-lg shadow-shadowOne"> 
           
            <img 
             className="w-full h-64 object-cover rounded-lg mb-2"
            src={contactImg} alt="contactImg"/>  

            <div className="flex flex-col gap-4">
            <h3 className="text-3xl font-bold text-white">{t("banner.MY_NAME")}</h3>
            <p className="text-lg font-normal text-gray-400">
            {t("banner.Full_Stack_Developer")}
            </p>
            <p className="text-base text-gray-400 tracking-wide">
             
            </p>
            <p className="text-base text-gray-400 flex items-center gap-2">
              Phone: <span className="text-lightText">+237 680 77 03 79</span>
            </p>
            <p className="text-base text-gray-400 flex items-center gap-2">
              Email: <span className="text-lightText">kouo.sylvain@yahoo.com</span>
            </p>
          </div>

          <div className="flex flex-col gap-4">
          <h2 className="text-base uppercase font-titleFont mb-4">{t("banner.Find_me_on")}</h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
             {/* <FaFacebookF />*/}
              <a href="https://www.facebook.com/kouopehuie.sylvain/" target="_blank" rel="noreferrer"> <FaFacebookF/></a> 
            </span>
            
            <span className="bannerIcon">
            <a href="https://www.linkedin.com/in/kouo-pehuie-sylvain-74164a161/" target="_blank" rel="noreferrer"> <FaLinkedinIn/></a> 
            </span>

            <span className="bannerIcon">
            <a href="https://wa.me/237658096189/" target="_blank" rel="noreferrer"><FaWhatsapp/></a>
            </span>

           {/* <span className="bannerIcon">
              <FaTwitter />
            </span>*/}
          </div>
          </div>
           </div>
  )
}

export default ContactLeft