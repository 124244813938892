import React from 'react'

const ProjectsCard = ({ title, description, src,lien }) => {
  return (
    <div className="w-full p-4 xl:px-12 h-auto xl:py-10 rounded-lg shadow-shadowOne flex flex-col bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-gray-900 hover:gray-900 transition-colors duration-1000">
      <div className="w-full h-[80%] overflow-hidden rounded-lg">
        <img
          className="w-full h-60 object-cover group-hover:scale-110 duration-300 cursor-pointer"
          src={src}
          alt="src"
        />
      </div>
      <div className="w-full mt-5 flex flex-col  gap-6">
        <div>
          <div className="flex items-center justify-between">
            <h3 className="text-base uppercase text-designColor font-normal">
              {title}
            </h3>
            
          </div>
          <p className="text-sm md:text-sm sm:text-xs xs:text-xs xss:text-xs tracking-wide mt-3 hover:text-gray-100 duration-300">
            {description}
          </p>

          <p className="text-sm tracking-wide mt-3 hover:text-gray-100 duration-300">
            {lien}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProjectsCard