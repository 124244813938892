import React , {useState} from 'react'
import { useTranslation } from "react-i18next";
const ContactRight = () => {
  const [t,i18n] = useTranslation("global");
    
    const [username, setUsername] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

     // ========== Email Validation start here ==============
  const emailValidation = () => {
    return String(email)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };
  // ========== Email Validation end here ================

    const handleSend = (e) => {
    e.preventDefault();
    if (username === "") {
      setErrMsg(t("CONTACT.Username_is_required")+"!");
    } else if (phoneNumber === "") {
      setErrMsg(t("CONTACT.Phone_number_is_required")+"!");
    } else if (email === "") {
      setErrMsg(t("CONTACT.Please_give_your_Email")+"!");
    } else if (!emailValidation(email)) {
      setErrMsg(t("CONTACT.Give_a_valid_Email")+"!");
    } else if (subject === "") {
      setErrMsg(t("CONTACT.Please_give_your_Subject")+"!");
    } else if (message === "") {
      setErrMsg(t("CONTACT.Message_is_required")+"!");
    } else {
        setSuccessMsg(t("CONTACT.Thank_you")+`  ${username},`+t("CONTACT.Your_Messages_has_been_sent")+`!`
        );
        setErrMsg("");
        setUsername("");
        setPhoneNumber("");
        setEmail("");
        setSubject("");
        setMessage("");
        console.log(username,phoneNumber,email,subject,message);
      }
    }
  return (
    <div className="w-full lgl:w-[60%] h-full py-10 bg-gradient-to-r from-[#050506] to-[#010810] p-10 rounded-lg shadow-shadowOne"> 
               <form className="w-full flex flex-col gap-6 py-2">
                   { errMsg && (<p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                      {errMsg}</p>)}

                      {successMsg && (
                        <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                          {successMsg}
                        </p>
                      )}
                <div className="flex gap-10">
                    <div className="w-1/2 flex flex-col gap-2">
                    <p className="text-sm text-gray-400 uppercase tracking-wide">{t("CONTACT.YOUR_NAME")}</p>
                    <input onChange={(e)=>setUsername(e.target.value)} value={username} 
                    className={`${
                        errMsg === t("CONTACT.Username_is_required")+"!" &&
                        "outline-designColor"
                      } contactInput`} type="text"/>
                    </div>

                    <div className="w-1/2 flex flex-col gap-2">
                    <p className="text-sm text-gray-400 uppercase tracking-wide">{t("CONTACT.PHONE_NUMBER")}</p>
                    <input onChange={(e)=>setPhoneNumber(e.target.value)} value={phoneNumber} 
                    className={`${
                        errMsg === t("CONTACT.Phone_number_is_required")+"!" &&
                        "outline-designColor"
                      } contactInput`} type="text"/>
                    </div>
                
                </div>
               
                <div className="w-full flex flex-col gap-2">
                <p className="text-sm text-gray-400 uppercase tracking-wide">{t("CONTACT.EMAIL")}</p>
                <input onChange={(e)=>setEmail(e.target.value)} value={email} 
                className={`${
                    errMsg === t("CONTACT.Please_give_your_Email")+"!" &&
                    "outline-designColor"
                  } contactInput`} type="email"/>
                </div>
                   
                <div className="w-full flex flex-col gap-2">
                <p className="text-sm text-gray-400 uppercase tracking-wide">{t("CONTACT.SUBJECT")}</p>
                <input onChange={(e)=>setSubject(e.target.value)} value={subject} 
                className={`${
                    errMsg === t("CONTACT.Please_give_your_Subject")+"!" &&
                    "outline-designColor"
                  } contactInput`} type="text"/>
                </div>

                <div className="w-full flex flex-col gap-2">
                <p className="text-sm text-gray-400 uppercase tracking-wide">{t("CONTACT.YOUR_MESSAGE")}</p>
                <textarea onChange={(e)=>setMessage(e.target.value)} value={message} 
                className={`${
                    errMsg === t("CONTACT.Message_is_required")+"!" && "outline-designColor"
                  } contactTextArea`} cols="30" rows="8">

                </textarea>
                </div>

                <div className="w-full">
                  <button onClick={handleSend} className="w-full h-12 bg-[#141518] rounded-lg text-base text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-designColor border-transparent">
                  {t("CONTACT.SEND_MESSAGE")}
                  </button>
                </div>
                { errMsg && (<p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-orange-500 text-base tracking-wide animate-bounce">
                {errMsg}</p>)}

                {successMsg && (
                    <p className="py-3 bg-gradient-to-r from-[#1e2024] to-[#23272b] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                      {successMsg}
                    </p>
                  )}
                </form>
             
            </div>
  )
}

export default ContactRight