import React from 'react'
import Title from "../layouts/Title"
import ContactLeft from "./ContactLeft";
import ContactRight from "./ContactRight";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const [t,i18n] = useTranslation("global");
  return (
    <section
    id="contact"
    className="w-full-1/2 py-20 border-b-[5px] border-b-black">
    

    <div className="flex justify-center items-center text-center">
        <Title
          title={t("CONTACT.Contact")}
          description={t("CONTACT.CONTACT_WITH_ME")}
        />
      </div>

      <div className="w-full">
       <div className="w-full h-auto flex flex-col lgl:flex-row justify-between">
         <ContactLeft/>
         <ContactRight/>
          
         
        </div>
      </div>
      </section>
  )
}

export default Contact