import React from 'react';
import Title from "../layouts/Title";
import Card from "./Card";
import {AiFillAppstore,AiOutlineVideoCamera} from 'react-icons/ai';
import {HiDatabase}  from "react-icons/hi";
import {MdOutlineSchool} from "react-icons/md";
import { useTranslation } from "react-i18next";

const Features = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <section id="features" className="w-full h-auto py-10 flex flex-col border-b-[1px]
    border-b-black">
   <Title title={t("features.features")} description={t("features.What_I_Do")}/>
   <div className="grid grid-cols-1 md:grid-cols-2 gap-6 xl:gap-10">
   <Card
      title={t("features.Web_development")}
      description={t("features.description_webdev")}
      icon={<AiFillAppstore/>}/>
   <Card
   title={t("features.Data_management")}
   description={t("features.description_dbm")}
   icon={<HiDatabase/>}
   
   />
   <Card
   title={t("features.Teaching")}
   description={t("features.description_teaching")}
   icon={<MdOutlineSchool/>}/>
   <Card
   title={t("features.Video_Editing")}
   description={t("features.description_video_editing")}
   icon={<AiOutlineVideoCamera/>}/>
   </div>
   </section>
  )
}

export default Features