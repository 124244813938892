import React from 'react'
import Title from '../layouts/Title'
import { projectOne, projectTwo, projectThree } from "../../assets/index";
import ProjectsCard from './ProjectsCard';
import { useTranslation } from "react-i18next";

const Projects = () => {
  const [t,i18n] = useTranslation("global");
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title={t("projects.VISIT_MY_PROJECTS")}
          description={t("projects.My_Projects")}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="SCHOOL MANAGEMENT SOFTWARE"
          des=" Cette application dénommée Rapid School est un programme informatique 
          principalement destiné aux établissements d’établissement d’enseignement 
          secondaire général et technique. Ce programme apparait de nos jours comme le 
          produit le plus complet et le plus fiable en terme de gestion des établissements
          scolaire (voire l’unique). Il intègre un nombre important de fonctionnalités qui 
          rendent la gestion d'un établissement scolaire fiable, transparente et 
          efficace."
          src={projectOne}
        />
        <ProjectsCard
          title="Jobs news Website"
          des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          src={projectTwo}
        />
        <ProjectsCard
          title="Apsoldd website"
          des=" Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Explicabo quibusdam voluptate sapiente voluptatibus harum quidem!"
          src={projectThree}
        />
      </div>
    </section>
  );
}

export default Projects