import React from 'react'

const Title = ({title,description,icon}) => {
  return (
    <div className="flex flex-col gap-3 font-titleFont mb-14">
    <h3 className="text-sm  md:text-xs UPPERCASSE, font-light text-designColor tracking-wide">{title}</h3>
    <h1 className="text-5xl md:text-sm text-gray-400, font-bold capitalize">{description}</h1>
    </div>
  )
}

export default Title